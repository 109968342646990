import React from "react"
import { withRouter } from "react-router-dom"
import { withStyles } from "@material-ui/core/styles"
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import CustomTable from "../../components/datagrid/comumdatagrid"
import CustomDropDown from "../../components/dataselector/comumdropdown"

const useStyles = (theme) => ({
    container: {
      marginTop: theme.spacing(5),     
    },
  })
  

class VWgroupinterfaces extends React.Component {
  render() {
    const { classes } = this.props
    return (
      <React.Fragment>
        <Container maxWidth="lg" className={classes.container}>
          <Grid
            container
            spacing={3}
            direction="column"
            justify="space-between"
            alignItems="stretch">
            <Grid item>
              <CustomDropDown label="Grupo"/>
            </Grid>
            <Grid item>
                <CustomTable
                columns={ [
                    { title: 'ID', field: 'id', type: 'numeric', hidden: true },
                    { title: 'Nome', field: 'name' },
                    { title: 'Descrição', field: 'description'},
                    { title: 'Data Criação', field: 'dt_creation',type: 'datetime' },
                    { title: 'Ativo', field: 'ic_ativo',type: 'boolean' },
                  ]}
                data={
                    [
                        {id:1, name: 'Heros Mobile', description: 'best way to recive message', dt_creation: '26/07/2020', ic_ativo: true },
                        {id:2, name: 'Villans Fax', description: 'worst way to recive message', dt_creation: '27/07/2020', ic_ativo: false },
                        
                      ]

                }
                title = "Meios"

                />
            </Grid>

          </Grid>
        </Container>       
      </React.Fragment>
    )
  }
}

export default  withRouter(withStyles(useStyles, { withTheme: true })(VWgroupinterfaces))
