import React,{ useEffect } from 'react'
import ReactDOM from "react-dom"
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'

import PersonIcon from '@material-ui/icons/Person'


import WebIcon from '@material-ui/icons/Web'

import GroupIcon from '@material-ui/icons/Group'
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import LibraryAddIcon from '@material-ui/icons/LibraryAdd'

import LoyaltyIcon from '@material-ui/icons/Loyalty'
import DevicesOtherIcon from '@material-ui/icons/DevicesOther'
import AddBoxIcon from '@material-ui/icons/AddBox'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import ChatIcon from '@material-ui/icons/Chat'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MenuIcon from '@material-ui/icons/Menu'
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Link } from "react-router-dom"
import Badge from '@material-ui/core/Badge'
import {getUnreadByUserID} from "../../services/chat/message"

import UpdateIcon from '@material-ui/icons/Update'

import Fab from '@material-ui/core/Fab'
import InfoIcon from '@material-ui/icons/Info'
import WarningIcon from '@material-ui/icons/Warning'

import CheckCircleIcon from '@material-ui/icons/CheckCircle'

import Chip from '@material-ui/core/Chip'
import Grid from '@material-ui/core/Grid'

import OneSignal from 'react-onesignal'


const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  fabStyle: {
    margin: 0,
    top: 0,
    right: 'auto',
    bottom: 'auto',
    left: 20,
    position: 'fixed',
  },
  pushbuttonActive: {
    //backgroundColor: '#F9F871'
    backgroundColor: '#23BEB6'
    
  },
  pushbuttonWarning: {
    backgroundColor: '#F9F871'
    //backgroundColor: '#23BEB6'
    
    
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}))

function Menu(props) {
  const { window } = props
  const classes = useStyles()
  const theme = useTheme()
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [messages,setMessages] = React.useState("0")
  const [PushButton,setPushButton] = React.useState(<div></div>)
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }
  //OneSignal.initialize('6fa11b47-1c2b-446c-a79e-d85adfc65627')

  const handlerPushButtonClick = async () => {
    try {
      await OneSignal.getPlayerId().then(
        result=>{
          console.log("player_id ====>")
          console.log(result)
          console.log(result.length)
        })
    }
    catch (e) {
      console.log("sem player id") 
    }
             
    }

    


  const handleCheckNotification = async () => {
    var retorno = false
    try {
      await OneSignal.getPlayerId().then(
        result=>{
          console.log(result)
          if(result == null)
          {
            retorno = false
          }
          else{
            retorno = true
          } 
        }
      )
      return retorno

   }
   catch (e) {
 
    return false }
     
    
  }
  

    




  let getMessages = async () => { 
    await getUnreadByUserID(localStorage.getItem("user_id")).then(response =>  {

      setMessages(response.data[0].unreadead_message)
      ReactDOM.render("Violeta Notification - "+response.data[0].unreadead_message+" mensagens não lidas",document.getElementById("violeta-notification-title"))
    })
  }

  useEffect(() => {
    getMessages()
    handleCheckNotification().then(result => {
      console.log("Check Notification ====> ")
      console.log(result)
      if(result === true)
      {
        setPushButton(<Button
          variant="contained"
          size="small"
          className={classes.pushbuttonActive}
          startIcon={<CheckCircleIcon />}
          onClick={handlerPushButtonClick}
        >
          Alerta<br/>Ativo
        </Button>)
      }else{
        
        setPushButton(<Button
            variant="contained"
            size="small"
            className={classes.pushbuttonWarning}
            startIcon={<WarningIcon />}
            onClick={handlerPushButtonClick}
          >
            
          Alerta<br/>Inativo
          </Button>)
  
    }})
    //handleCheckNotification() 
  }, [])

  // TODO: CREATE PERMISSSIONS
  const isAdmin = localStorage.getItem("isa")
  let drawer = (<div></div>)

  if(isAdmin === 'true') {
  drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
        <List>
        <ListItem button key="application" component={Link} to="/administration/application">
            <ListItemIcon><WebIcon  /></ListItemIcon>
            <ListItemText primary="Aplicações" />
        </ListItem>
        </List>
        <Divider />
        <List>              
        <ListItem button key="users" component={Link} to="/administration/user">
            <ListItemIcon><PersonIcon  /></ListItemIcon>
            <ListItemText primary="Usuários" />
        </ListItem>
        </List>
        <Divider />
        <List>          
        <ListItem button key="group" component={Link} to="/administration/group">
            <ListItemIcon><GroupIcon   /></ListItemIcon>
            <ListItemText primary="Grupos" />
        </ListItem>
        <ListItem button key="groupusers" component={Link} to="/administration/groupusers">
            <ListItemIcon><GroupAddIcon    /></ListItemIcon>
            <ListItemText primary="Adicionar usuário a grupo" />
        </ListItem> 
        <ListItem button key="grouptags" component={Link} to="/administration/grouptags">
            <ListItemIcon><LibraryAddIcon      /></ListItemIcon>
            <ListItemText primary="Adicionar tag a grupo" />
        </ListItem>
        <ListItem button key="groupapplications" component={Link} to="/administration/groupapplications">
            <ListItemIcon><LibraryAddIcon /></ListItemIcon>
            <ListItemText primary="Adicionar aplicação a grupo" />
        </ListItem>
        </List>
        <Divider />
        <List>                         
        <ListItem button key="tags" component={Link} to="/administration/tags">
            <ListItemIcon><LoyaltyIcon/></ListItemIcon>
            <ListItemText primary="Tags" />
        </ListItem>
        <ListItem button key="tagsuser" component={Link} to="/administration/tagsuser">
            <ListItemIcon><NotificationsOffIcon/></ListItemIcon>
            <ListItemText primary="Tags Usuário" />
        </ListItem>
        </List>        
        <Divider />
        <List>                       
        <ListItem button key="chat" component={Link} to="/chat">
            <ListItemIcon>
            <Badge badgeContent={messages} color="primary"><ChatIcon/></Badge></ListItemIcon>
            <ListItemText primary="Mensagens" />
        </ListItem>                                 
        </List>
        <Divider />
        <List>
          <ListItem button key="logout" component={Link} to="/">
            <ListItemIcon><ExitToAppIcon/></ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>     
        </List>
     
    </div>
  )
  }
  else
  {
    drawer = (
      <div>
        <div className={classes.toolbar} />
        <Divider />
          <List>
          <ListItem button key="tagsuser" component={Link} to="/administration/tagsuser">
              <ListItemIcon><NotificationsOffIcon/></ListItemIcon>
              <ListItemText primary="Tags Usuário" />
          </ListItem>
          </List>
          <Divider />
          <List>                       
          <ListItem button key="chat" component={Link} to="/chat">
              <ListItemIcon>
              <Badge badgeContent={messages} color="primary"><ChatIcon/></Badge></ListItemIcon>
              <ListItemText primary="Mensagens" />
          </ListItem>                  
  
         
        </List>
        <Divider />
        <List>
          <ListItem button key="logout" component={Link} to="/">
            <ListItemIcon><ExitToAppIcon/></ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>     
        </List>
  
       
      </div>
    )
  }
  // END TODO: CREATE PERMISSSIONS

  const container = window !== undefined ? () => window().document.body : undefined

  return (
    <div className={classes.root}>
      <CssBaseline />

      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
        <Grid
          justify="space-between" // Add it here :)
          container 
          spacing={24}
        >
        <Grid item>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton></Grid>
          <Grid item>
          <Typography variant="h7" noWrap>
            {props.title}
          </Typography></Grid>
          <Grid item>
          {PushButton}
          <Chip
          size="small"
          icon={<UpdateIcon />}
          label={process.env.REACT_APP_VERSION}
          color="primary"/>
          <Fab size="small" component={Link} to="/about" color="primary" aria-label="sobre">
          <InfoIcon/>
            </Fab>
          </Grid>

        </Grid>
        </Toolbar>
      </AppBar>
      
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <props.component {...props} />

      </main>
    </div>
  )
}

Menu.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
}

export default Menu