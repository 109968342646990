import api from "../api/api"

export const getAll = async (entity_endpoint) =>{
    return api
    .get(entity_endpoint)
    .then((res) => {
      console.log("GET ALL SUCESSO ---> " + entity_endpoint)
      console.log(res)
      return res
    })
    .catch((err) => {
      console.log("GET ALL ERRO ---> " + entity_endpoint)
      console.log(err)
      return null})
}

export const insertOne = async (entity_endpoint,postbody) =>{
    let myendpoint = entity_endpoint + "/add"
    console.log("POST BODY DO INSERT ---->" + myendpoint)
    console.log(postbody)
    return api
    .post(myendpoint,postbody)
    .then((res) => {
        console.log("INSERT SUCESSO ---> " + myendpoint)
        console.log(res.data)
        return res
      })
      .catch((error) => {
        console.log("INSERT ERRO ---> " + myendpoint)
        console.log(error)
        if (error.response) {
        } else if (error.request) {
           console.log(error.request);
        } else {
            console.log('Error', error.message);
        }
        console.log(error.config);
        return null})
}

export const updateOne = async (entity_endpoint, postbody, id) =>{

  let myendpoint = entity_endpoint + `/${id}`
  console.log("BODY DO UPDATE ---->" + myendpoint)
  console.log(postbody)
  api
  .post(myendpoint,postbody)
  .then((res) => {
    console.log("SUCESSO UPDATE")
    console.log(res.data)
    return res})
  .catch((err) => {
    console.log("UPDATE ERRO ---> " + myendpoint)
    console.log(err)
    return null})
}


export const deleteOne = async (entity_endpoint, postbody) =>{

  let myendpoint = entity_endpoint + "/delete"
  console.log("BODY DO DELETE ---->" + myendpoint)
  console.log(postbody)
 api
  .delete(myendpoint,{
    data: postbody
  })
  .then((res) => {
    console.log("SUCESSO DELETE")
    return res})
  .catch((err) => {
    console.log("DELETE ERRO ---> " + myendpoint)
    console.log(err)
    return null})
}