import axios from "axios"

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_LOGIN_URL
})

instance.defaults.headers.common["Content-Type"] = "application/json"
instance.defaults.headers.common["Channel"] = "servico-plug-play"



instance.interceptors.response.use((response) => {
  //console.log(response.headers)
  return response;
},(error) => {
  return Promise.reject(error);
})



export default instance
