import React from "react"
import { withRouter } from "react-router-dom"
import { withStyles } from "@material-ui/core/styles"
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import CustomTable from "../../components/datagrid/comumdatagrid"


const useStyles = (theme) => ({
    container: {
      marginTop: theme.spacing(5),     
    },
  })
  
class VWuser extends React.Component {
  render() {
    const { classes } = this.props
    return (
      <React.Fragment>
        <Container maxWidth="lg" className={classes.container}>
          <Grid
            container
            spacing={3}
            direction="column"
            justify="space-between"
            alignItems="stretch">
            <Grid item>
                <CustomTable
                columns={ [
                    { title: 'ID', field: 'id_usuario', type: 'numeric', editable:'never', hidden: true},
                    { title: 'Nome', field: 'nome' },
                    { title: 'Login', field: 'login'},        
                    { title: 'Data Inclusão', field: 'dt_criacao',type: 'datetime', editable:'never' },
                    { title: 'Ativo', field: 'ic_ativo', type: 'boolean'},
                  ]}
                entity_endpoint="user"
                id_field="id_usuario"
                title = "Usuários"
                />
            </Grid>
          </Grid>
        </Container>       
      </React.Fragment>
    )
  }
}

export default  withRouter(withStyles(useStyles, { withTheme: true })(VWuser))
