import React, { Component } from "react"
import loadinggif from "../../assets/images/spiners/loading.svg"
import { withStyles } from "@material-ui/core/styles"

const useStyles = (theme) => ({
    media: {
      paddingTop: '1%', // 16:9,
      marginTop:'30'
    },
    })

class Loader extends Component {

  render() {

    return (
        <div align="center">
            <img alt="carregando" src={loadinggif} className="responsive"></img>
        </div>

    )
  }
}
export default withStyles(useStyles, { withTheme: true })(Loader)
