import React, { Component } from "react"
import PropTypes from 'prop-types'
import { withStyles } from "@material-ui/core/styles"
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import {getAll} from "../../services/datagrid/handle-data"


const useStyles = (theme) => ({
  media: {
    paddingTop: '1%', // 16:9,
    marginTop:'30'
  },
  })
  
class ComumDropDown extends Component {
    constructor(props) {
      super(props)

      this.history = props.history
    }
    

    state = {
      isLoading: true,
      data: this.props.data,
      error: ""
    }


      componentDidMount() {
        getAll(this.props.entity_endpoint).then(response =>  {
          this.setState({data: response.data,isLoading: false})
      })
      }

      handleChange(props,e) {
          this.props.onChange(e.target.value)
      }

    render() {
        const { classes } = this.props
        let {label} = this.props
        return (
          <FormControl required className={classes.formControl}>
          <InputLabel id={label}>{label}</InputLabel>
          <Select
            labelId={label}
            //value={age}
            //onChange={this.handleChange}
            onChange={(e) => this.handleChange(this.props, e)}
            
            className={classes.selectEmpty}
          >
            {(this.state.isLoading === true)? <MenuItem key="Carregando" value="">Carregando...</MenuItem> : 
            this.state.data.map( mapvalue =>
            <MenuItem key={mapvalue[this.props.id_field]} value={mapvalue[this.props.id_field]}>{mapvalue[this.props.label_field]}</MenuItem>)
            }
          </Select>
          <FormHelperText>Obrigatório</FormHelperText>
        </FormControl>

        )
    }
}

ComumDropDown.propTypes = {
  onChange: PropTypes.func
}
export default withStyles(useStyles, { withTheme: true })(ComumDropDown)