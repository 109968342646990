import React from "react"
import { Link } from 'react-router-dom';
import Typography from "@material-ui/core/Typography"
import Paper from "@material-ui/core/Paper"
import Container from "@material-ui/core/Container"
import { withStyles } from "@material-ui/core/styles"
import { withRouter } from "react-router-dom"
import Image from "../../assets/images/404.jpg"

const useStyles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundImage: `url(${Image})`,
    backgroundSize: "cover",
    backgroundPosition: "center center",
    height:500
  },

})




class VW404 extends React.Component {
  constructor(props) {
    super(props)
    this.history = props.history
  }
    
    
  render() {
    const { classes } = this.props
    return (
      <React.Fragment>
        <Container component="main" maxWidth="lg">
          <Paper className={classes.paper} elevation={3}>

          <Typography component="h1" variant="h3">
          Oh não! Parece que você se perdeu!
          </Typography>
          <br/>
          <br/>

            <Link  to="/home">
                <Typography color="secondary" component="h1" variant="h3">
                    Voltar para página inicial
                </Typography>
            </Link>

          </Paper>
        </Container>
      </React.Fragment>
    )
  }
}

export default withRouter(withStyles(useStyles, { withTheme: true })(VW404))
