import {toast } from 'react-toastify';

let toastType =  (type, message) =>{
  if(type === "success"){
    return toast.success(message, {
      position: "top-center",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      }) 
  }
  else if(type === "warning"){
    return toast.warning(message, {
      position: "top-center",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      })  
  }
}

export const handleError = async (res) =>{

    console.log(res)
    let data = res.data

    if (data.hasOwnProperty("errorMessage")){
      let errorMessage = JSON.parse(data.errorMessage)
      if (errorMessage.statusCode === 500){
        return toastType("warning",errorMessage.body.friendly_message)
      }
      else if(errorMessage.statusCode === 403){
        return toastType("warning",errorMessage.body.friendly_message)
      }
    }
    else{
      return toastType("success","Sucesso")
    }
}
    