import React from "react"

import Typography from "@material-ui/core/Typography"
import Paper from "@material-ui/core/Paper"
import Container from "@material-ui/core/Container"
import { withStyles } from "@material-ui/core/styles"
import { withRouter } from "react-router-dom"
import HomeIcon from "@material-ui/icons/Home"

const useStyles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

})


class VWhome extends React.Component {
  constructor(props) {
    super(props)
    this.history = props.history
  }

  componentDidMount() {    
    if(localStorage.getItem("user_id") !== null){
      window.onesignal_id(localStorage.getItem("user_id"))
    } 
  }
  

  handleIconClick = () => {
    this.history.goBack()
  }
  render() {
    const { classes } = this.props
    return (
      <React.Fragment>
        <Container component="main" maxWidth="lg">
          <Paper className={classes.paper} elevation={3}>

          <HomeIcon color="primary" />

          <Typography component="h1" variant="h4">
          Bem vindo ao Violeta Alertas
          </Typography>
          <br></br>
          <Typography component="h1" variant="h5">
          <p>O Violeta Alertas é um portal para gerir notificações enviadas pelo hospital Sírio-Libanês</p>
          <p>Desenvolvido pela Squad de dado clínico-radiológico.</p>

          </Typography>
          </Paper>
        </Container>
      </React.Fragment>
    )
  }
}

export default withRouter(withStyles(useStyles, { withTheme: true })(VWhome))
