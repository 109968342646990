import React, { useEffect,useState } from "react"
import MaterialTable from "material-table";
import {getAll,insertOne,updateOne,deleteOne} from "../../services/datagrid/handle-data"
import {handleError} from "../../services/errors/handle-error"
import Loader from "../loader/loader"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
  
  let CustomTable = (props) => {
    
    const [controlrefresh,setControlRefresh] = useState({
      refreshValue: false
    })

    const [state, setState] = useState({
        columns: props.columns,
        isLoading: true,
        data: props.data,
        error: ""
      })
                    
    let insertData = async (postbody) =>{
      await insertOne(props.entity_endpoint, postbody).then( (res) => {
        handleError(res)

      })
    }

    let removeData = async (postbody) =>{
      await deleteOne(props.entity_endpoint,postbody)
      fetchData()
      toast.success("Removido com sucesso", {
        position: "top-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        })  
    }

    let updateData = async (postbody, id) =>{
      await updateOne(props.entity_endpoint,postbody, id)
      fetchData()
    }

    let fetchData = async () => {
      await getAll(props.entity_endpoint).then(response =>  {
          if(response !== null){
            setState({columns: props.columns,data: (response === null)?state.data:response.data,isLoading: false})
          }
        })
    }

    useEffect(() => {   
      fetchData()

    }, [controlrefresh.refreshValue])

    useEffect(() => {  
      fetchData()

    }, [props.entity_endpoint])

    useEffect(() => {  
      fetchData()

    }, [props.data])

    useEffect(() => {
      
      if (typeof props.parent_data !== 'undefined' &&  props.parent_data.length){
        for (let value of props.parent_data) {

          for(let key of Object.keys(props.id_dropdown)){
            value[key] = props.id_dropdown[key]
          }
          if (props.action_type === "I"){
          insertData(value)
          }
          else if(props.action_type === "D")
          {
          removeData(value)
          }

        }
      }

    },[props.parent_data])

    return (
        <React.Fragment>
        {(state.isLoading === true)? <Loader/> : 
            <React.Fragment>
              <ToastContainer
                position="top-center"
                autoClose={10000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                pauseOnHover
                />
            <MaterialTable
            title={props.title}
            columns={state.columns}
            data={state.data}
            localization={{ body: { editRow: { deleteText: "Tem certeza que deseja remover o registro?" } } }}
            actions = {props.actions}
            options= {props.options}
            editable=
            {!props.not_editable?{ onRowAdd: (newData) =>
                new Promise((resolve) => {
                    setTimeout(() => {      
                        insertData(newData).then(()=>
                          {
                            setControlRefresh({refreshValue:!controlrefresh.refreshValue})
                            toast.success("Registro inserido com sucesso", {
                              position: "top-center",
                              autoClose: 10000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: false,
                              progress: undefined,
                              })                                
                          }
                        )
                        resolve()
                    }, 10);
                }),
                onRowUpdate: (newData, oldData) =>
                new Promise((resolve) => {
                    setTimeout(() => {
                      let id = props.id_field;
                      updateData(newData, newData[id])
                      .then(()=> {
                        setControlRefresh({refreshValue:!controlrefresh.refreshValue})
                        toast.success("Registro atualizado com sucesso", {
                          position: "top-center",
                          autoClose: 10000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: false,
                          progress: undefined,
                          })
                      }
                      )                        
                      resolve();
                    }, 10);
                }),

            }:{onRowUpdate: (newData, oldData) =>
              new Promise((resolve) => {
                  setTimeout(() => {
                    let id = props.id_field;
                    updateData(newData, newData[id])
                    .then(()=> {
                      setControlRefresh({refreshValue:!controlrefresh.refreshValue})
                      toast.success("Registro atualizado com sucesso", {
                        position: "top-center",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        })
                    }
                    )                        
                    resolve();
                  }, 10);
              })}}
            
        />
        </React.Fragment>
        }
        </React.Fragment>
    )
  }
  CustomTable = React.memo(CustomTable);
  export default (CustomTable)