import 'react-app-polyfill/ie11'
import React from "react"
import ReactDOM from "react-dom"
import { Router, Switch, Route, Redirect } from "react-router-dom"
import { createBrowserHistory } from "history"

import "./assets/css/main.css"
import { ThemeProvider } from "@material-ui/core/styles"
import CssBaseline from "@material-ui/core/CssBaseline"
import theme from "./assets/theme/main"



import VWlogin from "./views/login/login"
import VWhome from "./views/home/home"

import VWabout from "./views/about/about"
import VWabouthome from "./views/about/about-home"
import VWaboutsquad from "./views/about/about-squad"

import VWchatiterator from "./views/chat-iterator/chat-iterator"
import VWapplication from "./views/administration/application"
import VWuser from "./views/administration/user"
import VWgroup from "./views/administration/group"
import VWinterface from "./views/administration/interface"
import VWgroupusers from "./views/administration/group-users"
import VWinterfaceusers from "./views/administration/interface-users"
import VWgroupinterfaces from "./views/administration/group-interfaces"
import VWgrouptags from "./views/administration/group-tags"
import VWtag from "./views/administration/tag"
import VWtagsuser from "./views/administration/tags-user"
import VWredirect from "./views/url-redirect/redirect"
import VWgroupapplications from "./views/administration/group-applications"
import VWpushintegration from "./views/push-integration/pushintegration"


import VW404 from "./views/error/404"
import Menu from "./components/menu/menu"

import {
  isAuthenticated,
  setclaim
} from "./services/authenticate/authenticate"
//import {updateToken} from "./services/violeta-services/token"




if(!isAuthenticated())
{
  let search = window.location.search
  let params = new URLSearchParams(search)
  let hash_token = params.get('hash_token')
  let username = params.get('username')
  if(hash_token !== null && username !== null ){
    setclaim(hash_token,username,false)
    //TODO: DESCOMENTAR QUANDO VIRAR O VIOLETA
    //updateToken(hash_token).then(()=>{setclaim(hash_token,username)})   
  }
}
if(isAuthenticated())
{
  console.log("user_id ==> ")
  console.log(localStorage.getItem("user_id"))

  if(localStorage.getItem("user_id") !== null){
    window.onesignal_id(localStorage.getItem("user_id"))
  } 
}




const RouteCustom = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => <Component {...props} />} />
)

const RouteAbout = ({ component: Component,title: Title,history, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
        <VWabout component = {Component} title= {Title} {...props} />
    }
  />
)


const RouteSecure = ({ component: Component,title: Title,history, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Menu component = {Component} title= {Title} {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
)

const RouteIntegration = ({ component: Component,title: Title,history, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <VWpushintegration component = {Component} title= {Title} {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
)

const hist = createBrowserHistory()


ReactDOM.render(
  <Router history={hist}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Switch>
        <RouteCustom exact path="/" component={VWlogin} />
        <RouteSecure path="/home" component={VWhome} title="Home"/>
        <RouteAbout path="/about" component={VWabouthome} title="Sobre - Início"/>
        <RouteAbout path="/about-squad" component={VWaboutsquad} title="Sobre - Squad"/>  
              
        <RouteSecure path="/chat" component={VWchatiterator} title="Violeta Chat" />
        
        <RouteSecure path="/administration/application" component={VWapplication} title="Gestão de Aplicações" />
        <RouteSecure path="/administration/user" component={VWuser} title="Gestão de Usuários"/>
        <RouteSecure path="/administration/group" component={VWgroup} title="Gestão de Grupos"/>
        <RouteSecure path="/administration/groupusers" component={VWgroupusers} title="Adicionar usuários a grupo" />
        <RouteSecure path="/administration/groupinterfaces" component={VWgroupinterfaces}  title="Adicionar interfaces a grupo" />
        <RouteSecure path="/administration/grouptags" component={VWgrouptags}  title="Adicionar tag a grupo"/>
        <RouteSecure path="/administration/groupapplications" component={VWgroupapplications} title="Adicionar aplicações a grupo" />

        <RouteSecure path="/administration/interface" component={VWinterface}  title="Gestão de Interfaces"/>
        <RouteSecure path="/administration/interfaceusers" component={VWinterfaceusers}  title="Adicionar interfaces a usuários" />
        <RouteSecure path="/administration/tags" component={VWtag}  title="Gestão de Tags" />
        <RouteSecure path="/administration/tagsuser" component={VWtagsuser}  title="Gestão de Tags Usuario" />
         
        <RouteIntegration path="/pushintegration" component={VWpushintegration}  title="Registrando Alertas" />



        
        <RouteCustom path="/redirect" component={VWredirect} />

        <Route path="*" render={() => <VW404/>} />
      </Switch>
    </ThemeProvider>
  </Router>,
  document.getElementById("root")
)



