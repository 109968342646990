import api from "../api/api"

export const getByUserID = async (user_id) =>{
    
    let entity_endpoint = `message/user/${user_id}`

    return api
    .get(entity_endpoint)
    .then((res) => {
      console.log("GET MESSAGE USER SUCESSO ---> " + entity_endpoint)
      
    return res
    })
    .catch((err) => {
      console.log("GET MESSAGE USER ERRO ---> " + entity_endpoint)
      console.log(err)
      return null})
}

export const getUnreadByUserID = async (user_id) =>{
    
  let entity_endpoint = `message/user/${user_id}/unreadead`

  return api
  .get(entity_endpoint)
  .then((res) => {
    console.log("GET MESSAGE USER SUCESSO ---> " + entity_endpoint)
    
  return res
  })
  .catch((err) => {
    console.log("GET MESSAGE USER ERRO ---> " + entity_endpoint)
    console.log(err)
    return null})
}



export const getByUserIDIncrease = async (user_id, current_day='7', increase_day='0') =>{
    
  let entity_endpoint = `message/user/${user_id}/currentday/${current_day}/${increase_day}`

  return api
  .get(entity_endpoint)
  .then((res) => {
    console.log("GET MESSAGE USER SUCESSO ---> " + entity_endpoint)
    
    for (let data of res.data){
      if(!Boolean(data["dt_leitura"])){
        var today = new Date()
        var date = today.getDate() + '/' + (today.getMonth()+1) + '/' + today.getFullYear()
        var time = today.getHours() + ":" + today.getMinutes()
        data["dt_leitura"] = date+' '+time

      }
    }
  return res
  })
  .catch((err) => {
    console.log("GET MESSAGE USER ERRO ---> " + entity_endpoint)
    console.log(err)
    return null})
}
    
export const sendMessage = async (postbody) =>{
  let entity_endpoint = 'message/send'
  console.log("POST MESSAGE BODY ---->")
  console.log(postbody)

  return api
  .post(entity_endpoint,postbody)
  .then((res) => {
    console.log("POST MESSAGE USER SUCESSO ---> " + entity_endpoint)
    console.log(res)
    return res
  })
  .catch((err) => {
    console.log("POST MESSAGE USER ERRO ---> " + entity_endpoint)
    console.log(err)
    return null})
}

