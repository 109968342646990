import React, { Component } from "react"
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from "@material-ui/core/styles"
import { withRouter } from "react-router-dom"
import { Typography } from "@material-ui/core"
import AccessTime from "@material-ui/icons/AccessTime";
import PublicIcon from '@material-ui/icons/Public';
import MailOutlineRoundedIcon from '@material-ui/icons/MailOutlineRounded';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import Button from "@material-ui/core/Button"
import { Link } from "react-router-dom"



const useStyles = (theme) => ({
  root: {
    padding: '1%',
  },
  media: {
    height: 300,
    objectFit: 'cover'

  },
  avatar:{
    color: theme.palette.primary.dark,
    //backgroundColor: theme.palette.primary.dark,

  },
  icon_style:{
    //backgroundColor: '#ef5350',
    position: 'relative',
    marginTop: '10',
  },
  container:{
    //backgroundColor: '#ef5350',
    position: 'relative',
    display: 'flex',
    flexWrap:"wrap"
  },
  itemContent:{
    backgroundColor: '#ef5350',
  },
  itemDate:{
    position: "relative",
    
  },
  iframeStyle:{
    overflow: "hidden",
    height: "800px"


  },  
  })
  
class MessageCard extends Component {
    constructor(props) {
      super(props)
      this.history = props.history
      this.state = {
        showService: false,
      }
    }

    handleLoadMore = (e) => {
      this.setState({showService: true})  }

    render() {
        const { classes } = this.props
        let {content,dt_recived, dt_read, servico, message_title, icon, attachment, media_image, link,external_id} = this.props
        const {showService} = this.state
        /*
        attachment: anexo
        link: url_site
        */
        return (
            <Card >
              <CardHeader title={ 
                  <Typography variant="h6">{message_title}</Typography>}
                  avatar={ 
                  <Avatar  alt="Violeta" src={icon} className = {icon === ""?null:classes.avatar}>
                      {icon === ""?<MailOutlineRoundedIcon/>:null}
                  </Avatar>
                  
                  }/>
              {(media_image)?
              <CardMedia
                className={classes.media}
                image={media_image}
              />:null}
              <CardContent className = {classes.container}>
              <Grid
                    container
                    direction="column"
                    justify="flex-start"> 
                  <Typography variant="body1" >
                    {content}   
                  </Typography>

              {(servico && !showService)?
                
                    
                    <Button
                      fullWidth
                      variant="contained"              
                      color="primary"
                      className={classes.submit}
                      onClick={this.handleLoadMore}
                      >Carregar serviço</Button>
              :null}
              {(showService)?                                    
                    <div>
                    <iframe id="embbedhtml" title={servico} className={classes.iframeStyle}
                        width="100%"
                        src={servico}
                        frameBorder="0" allowFullScreen=""
                        >
                    </iframe>            
                    </div>
              :null}

               </Grid>   

              </CardContent>    
              <Divider/>
              <CardActions>
                  <Grid
                   container
                   direction="row"
                   justify="flex-start"> 
                  {(link) ?
                      <IconButton  component={Link} to={{
                        pathname: '/redirect',
                        state: {
                          url: link,
                          external_id: external_id
                        }
                      }} color="primary">
                        <PublicIcon/>
                      </IconButton>

                    :
                    null 
                    //console.log("Sem link")
                   }
                  {(attachment) ?

                    <IconButton href = {attachment} color="primary">
                      <AttachFileIcon/>
                    </IconButton>

                    :
                    null 
                    //console.log("Sem attachment")
                   }
                                       </Grid>
                  <Grid
                    container
                    direction="column"
                    justify="flex-end"
                    alignItems="flex-end">
                    
                    <Typography variant="caption">
                    <AccessTime  className = {classes.icon_style} />{" Enviada: " + dt_recived}
                    </Typography>

                    <Typography variant="caption">
                    <DoneAllIcon  className = {classes.icon_style} />{(dt_read) ? " Lida: " + dt_read:"Lida: --- "}
                    </Typography>
                  </Grid>
              </CardActions>
            </Card>
        )
    }
}
export default withRouter(withStyles(useStyles, { withTheme: true })(MessageCard))