import api from "../api/api-login"
import {setUserClaim} from "../claim/claim"


export const servicelogin = async (requestbody) =>{
  let entity_endpoint = "/employee"
  return api
  .post(entity_endpoint,requestbody)
  .then((res) => {

    console.log('aqui',res.data.accessToken)

    if(res.data.accessToken){
      return true 
    }

    return false


      // let auth = res.data.substring(res.data.indexOf("Auth")+6)
      // auth = auth.substring(0,auth.length-1).trim()
      // if(auth.toUpperCase()==="TRUE"){
      //     return true 
      //   }
      //   else{ 
      //     return false
      //   }  
  })
  .catch((err) => {
    console.log("LOGIN ERRO ---> " + entity_endpoint)
    console.log(err)
    return null})
}





/*export const servicelogin = async (requestbody) =>
  api
    .post("auth", requestbody)
    .then((res) => res)
    .catch((err) => err.response)
*/

export const setclaim = (accessToken,username,isAdmin) => {
  localStorage.setItem("token", accessToken)
  localStorage.setItem("adlogin", username)
  localStorage.setItem("isa", isAdmin)

  setUserClaim(username)
  api.defaults.headers.common["Authorization"] =
    "Bearer " + accessToken

}


export const revoketoken = () => {
  localStorage.removeItem("token")
  localStorage.removeItem("adlogin")

  //api.defaults.headers.common["Authorization"] = "Bearer "
}

export const isAuthenticated = () => localStorage.getItem("token") !== null;