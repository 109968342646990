import React from "react"
import { withRouter } from "react-router-dom"
import { withStyles } from "@material-ui/core/styles"
import BoxLogin from "../../components/login/boxlogin"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Container from '@material-ui/core/Container';
import Fab from '@material-ui/core/Fab';
import InfoIcon from '@material-ui/icons/Info';


const useStyles = (theme) => ({
  container: {
    marginTop: theme.spacing(5),     
  },
  fabStyle: {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
  },
})

class VWlogin extends React.Component {
  constructor(props) {
    super(props)
    this.history = props.history
  }
  handleInfoClick = (e) => {
    this.history.push("/about")}

  render() {
    const { classes } = this.props    
    return (
      <React.Fragment>
        <Container maxWidth="lg" className={classes.container}>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
        >
        <Grid item>
          <Typography variant="h4">
          VIOLETA ALERTAS
          </Typography>
        </Grid>  
        <Grid item>
        <BoxLogin />
        </Grid>

        </Grid>    
        </Container>
        <Fab size="small" onClick={this.handleInfoClick} color="primary" aria-label="sobre" className={classes.fabStyle}>
          <InfoIcon/>
        </Fab>
      </React.Fragment>
    )
  }
}

export default  withRouter(withStyles(useStyles, { withTheme: true })(VWlogin))
