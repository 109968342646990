import React,{ useEffect } from 'react'
import ReactDOM from "react-dom"
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'

import PersonIcon from '@material-ui/icons/Person';


import WebIcon from '@material-ui/icons/Web';

import GroupIcon from '@material-ui/icons/Group';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';

import LoyaltyIcon from '@material-ui/icons/Loyalty';
import DevicesOtherIcon from '@material-ui/icons/DevicesOther';
import AddBoxIcon from '@material-ui/icons/AddBox';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ChatIcon from '@material-ui/icons/Chat';
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MenuIcon from '@material-ui/icons/Menu'
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Link } from "react-router-dom";
import Badge from '@material-ui/core/Badge';
import {getUnreadByUserID} from "../../services/chat/message"

import UpdateIcon from '@material-ui/icons/Update';



import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}))

function VWabout(props) {
  const { window } = props
  const classes = useStyles()
  const theme = useTheme()
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [messages,setMessages] = React.useState("0")
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  let getMessages = async () => { 
    await getUnreadByUserID(localStorage.getItem("user_id")).then(response =>  {

      setMessages(response.data[0].unreadead_message)
      ReactDOM.render("Violeta Notification - "+response.data[0].unreadead_message+" mensagens não lidas",document.getElementById("violeta-notification-title"))
    })
  }

  useEffect(() => {  
    getMessages() 
  }, [])


  let drawer = (<div></div>)

  drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List>
          <ListItem button key="squad" component={Link} to="/about-squad">
            <ListItemIcon><GroupIcon/></ListItemIcon>
            <ListItemText primary="Quem Somos" />
          </ListItem>     
        </List>
     
      <Divider />
        <List>
          <ListItem button key="home" component={Link} to="/">
            <ListItemIcon><ExitToAppIcon/></ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>     
        </List>
     
    </div>
  )
  

  // END TODO: CREATE PERMISSSIONS

  const container = window !== undefined ? () => window().document.body : undefined

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
        <Grid
          justify="space-between" // Add it here :)
          container 
          spacing={24}
        >
        <Grid item>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton></Grid>
          <Grid item>
          <Typography variant="h6" noWrap>
            {props.title}
          </Typography></Grid>
          <Grid item>

          <Chip
          size="small"
          icon={<UpdateIcon />}
          label={process.env.REACT_APP_VERSION}
          color="primary"/>
          </Grid>
        </Grid>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <props.component {...props} />

      </main>
    </div>
  )
}

VWabout.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
}

export default VWabout