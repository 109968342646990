import React from 'react'
import { withRouter } from "react-router-dom"
import { withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import InputAdornment from '@material-ui/core/InputAdornment';
import KeyboardIcon from '@material-ui/icons/Keyboard';
import TextField from '@material-ui/core/TextField';
import MessageCard from "../../components/message/messagecard"
import {getByUserID, getByUserIDIncrease, sendMessage} from "../../services/chat/message"
import Loader from "../../components/loader/loader"
import loadinggif from "../../assets/images/typing/typing.svg"
import Chip from '@material-ui/core/Chip';
import DateRangeIcon from '@material-ui/icons/DateRange';

const useStyles = (theme) => ({
  container: {
    marginTop: theme.spacing(5),     
  },
  spaceTxt: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(10)
  },
})

//const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop) 
  
class VWchatiterator extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      messages:[],
      days:0,
      messagesupdated:false,
      messagesent:false,
      text:'',
      isProcessing:false,
      range:7
    }
    this.handleChange = this.handleChange.bind(this);
    this.keyPress = this.keyPress.bind(this);
  }
  

  componentDidMount(){
    // ULTIMOS 7 DIAS --CARREGAR MAIS A CADA CLIQUE 
    // message/user/${user_id}/days/${days}
    // message/user/1/currentdays/7/add_day/1
    // 1 loading: 7
    // 8 dias ... 9
    // message = 7 dias 
    // message append com nova lista  
    
    // ESTADO INICIAL - 7 dias
    // CLIQUE DO BOTÃO - + 1 dia a mais 
    
    // Thread apartada - que verificar se tem mensagem nova baseado na mais recente da lista.
    // timer() olha de 30 em 30 segundos se tem novas messages 
    //(PEGA NA LISTA REGISTRO COM DATA MAIS RECENTE )
    // Passa na funciontion esse registro ... na query olha se tem algum mais recente... retorna lista e da novo append
    
    getByUserID(localStorage.getItem("user_id")).then(response => {
        this.setState({messages: response.data, isLoading: false, messagesupdated:true})
  })


  //scrollToRef(this.txtMessage)
  /*
    let search = window.location.search
    let params = new URLSearchParams(search)
    let message_id = params.get('message_id')
    if(message_id !== null){
      this.message_id.focus()
    }
    
    <Chip label="08/09/2020" color="primary" />

    */
  }
 

  componentDidUpdate(){
    if(this.state.messagesent)
    {

      getByUserIDIncrease(localStorage.getItem("user_id"), 7, 0).then(response => {

        let mergedList = this.state.messages

        mergedList.concat(response.data)

        this.setState({messages: mergedList,messagesent: false})

      })

    }
    else if(!this.state.messagesupdated)
    {
      getByUserIDIncrease(localStorage.getItem("user_id"), 7, this.state.days).then(response => {
      

        let mergedList = this.state.messages

        response.data.forEach(function (item, indice, array) {
          mergedList.unshift(item)
        })
        

        this.setState({messages: mergedList,messagesupdated: true})


    })
    }
  }

  handleLoadMore = (e) => {
    this.setState({days: this.state.days + 1,range: this.state.range + 1,messagesupdated: false})  }

  handleChange(event) {
      this.setState({text: event.target.value});
  }

  keyPress = (e) => {
    if(e.keyCode === 13){
        // put the login here
        let postbody = {"user_id" : localStorage.getItem("user_id"),"mensagem" : e.target.value.replace("\\", "\\\\")}
        this.setState({isProcessing: true})
        sendMessage(postbody).then(() =>  {
        getByUserID(localStorage.getItem("user_id"))
        .then(response =>  {
          this.setState({messages: response.data,isLoading: false,messagesent: true})
          this.setState({isProcessing: false})
        })
        })
        this.setState({text:''})
    }
  }
   
  render() {
    const { classes } = this.props    
    const {messages, isLoading,isProcessing,range } = this.state
    return (
      <React.Fragment>
        <Container maxWidth="lg"  className={classes.container}>
          <Grid
            container
            spacing={3}
            direction="column"
            justify="space-between"
            alignItems="stretch">
            {(isLoading)?<Loader/>:
            <React.Fragment>
            <Grid item key="date-range" >
            <Chip
              icon={<DateRangeIcon />}
              label={`Últimos ${range} dias`}             
              color="secondary"/></Grid>
            <Grid item key="btnLoadMore" >
              <Button
                fullWidth
                variant="contained"              
                color="primary"
                className={classes.submit}
                onClick={this.handleLoadMore}
              >
                Carregar mais
              </Button>
            </Grid>

              {messages.map(mapvalue =>
              <Grid item key={mapvalue["id_mensagem"]}>                
                <MessageCard type="" attachment={mapvalue["url_anexo"]} servico={mapvalue["url_servico"]} media_image={mapvalue["url_image"]} link={mapvalue["url_site"]} message_title={mapvalue["titulo"]} icon={mapvalue["url_icone"]}  content={mapvalue["mensagem"]} dt_recived={mapvalue["dt_envio"]} dt_read={mapvalue["dt_leitura"]} external_id={mapvalue["id_mensagem_externo"]}/>
              </Grid>
              )}
            </React.Fragment>}
            <React.Fragment>
              {isProcessing?        
                <div align="center">
                  <img alt="carregando" src={loadinggif} className="responsive"></img>
                </div>:null}
            </React.Fragment>
            <TextField
            className = {classes.spaceTxt}
            value={this.state.text}
            id="txtMessage"
            key="txtMessage"
            onChange={this.handleChange}
            onKeyDown={this.keyPress}
            autoFocus 
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <KeyboardIcon />
                  </InputAdornment>
                ),
              }}
            /> 
          </Grid>
        </Container>       
      </React.Fragment>
    )
  }
}

export default  withRouter(withStyles(useStyles, { withTheme: true })(VWchatiterator)) 
