import React, { Component } from "react"
import Paper from "@material-ui/core/Paper"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Link from "@material-ui/core/Link"
import Grid from "@material-ui/core/Grid"
import Backdrop from '@material-ui/core/Backdrop';
import Typography from "@material-ui/core/Typography"
import { withStyles } from "@material-ui/core/styles"
import { withRouter } from "react-router-dom"
import Loader from "../../components/loader/loader"
import {
  servicelogin,
  setclaim,
  revoketoken,
} from "../../services/authenticate/authenticate"
import {setUserClaim} from "../../services/claim/claim"
const useStyles = (theme) => ({
  papercontainer: {
    marginTop: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justify:"center",
    
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },  
})

class Login extends Component {
  constructor(props) {
    super(props)
    this.history = props.history
  }

  componentDidMount() {
    revoketoken()
  }
  state = {
    user: "",
    pwd: "",
    error: "",
    open: false
    }


  handleToggle =  () => {
    this.setState({ open: !this.state.open  })
  }

  handleSignIn = async (e) => {
    e.preventDefault()
    this.handleToggle()
    const { user, pwd } = this.state
    let body = {"username": user,"password": pwd};

    servicelogin(body).then( res => 
      {
        if (res === true) {
          setclaim("FAKETOKEN",user,true)

          setUserClaim(user).then(
            res =>{
            if (res === true){
              this.history.push("/home")
            }
            else{
              this.setState({ error: "erro no serviço de login" })
            }
          }
          )

         
        }
        else {
          this.setState({ error: "usuário e senha inválidos" })
        }
      })

    //CORRETO
    /*servicelogin(body).then((response) => {
      //
      let data = response.data

      if (response.status === 200) {
        setclaim(data.accessToken)
        this.history.push("/home")
      } else if (response.status === 403) {
        this.setState({ error: data.detail })
        revoketoken()
      } else {
        this.setState({ error: "usuário e senha inválidos" })
      }
    })*/
  }
  render() {
    const { classes } = this.props

    return (

        <Paper className={classes.papercontainer} square={false} elevation={3}>
        <Backdrop className={classes.backdrop} open={this.open} >
          <Loader/>
        </Backdrop>
        <div className={classes.papercontainer}>
          <Typography component="h1" variant="h5">
            Autenticação
          </Typography>
          <form onSubmit={this.handleSignIn} className={classes.form}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="user"
              label="Usuário"
              name="user"
              autoFocus
              onChange={(e) => this.setState({ user: e.target.value })}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="password"
              label="Senha"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => this.setState({ pwd: e.target.value })}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"              
              color="primary"
              className={classes.submit}
            >
              ENTRAR
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Esqueceu a senha?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Solicitar acesso"}
                </Link>
              </Grid>
            </Grid>
            {this.state.error && <p>{this.state.error}</p>}
          </form>
        </div>
        </Paper>

    )
  }
}
export default withRouter(withStyles(useStyles, { withTheme: true })(Login))
