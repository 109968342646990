import React from "react"
import { withRouter } from "react-router-dom"
import { withStyles } from "@material-ui/core/styles"
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import CustomTable from "../../components/datagrid/comumdatagrid"
import CustomDropDown from "../../components/dataselector/comumdropdown"


const useStyles = (theme) => ({
    container: {
      marginTop: theme.spacing(5),     
    },
  })

class VWgrouptags extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      selected_id: 0,
      modal: false,
      parent_data:[],
      action_type:null,
    }
  }
 
  changeHandler = (e) => {
    this.setState({selected_id: e})
  }

  render() {
    const { classes } = this.props
    const { selected_id, modal, parent_data,action_type } = this.state

    return (
      <React.Fragment>
        <Container maxWidth="lg" className={classes.container}>
          <Grid
            container
            spacing={3}
            direction="column"
            justify="space-between"
            alignItems="stretch">
            {modal === true?
            <Grid item>
              <CustomTable
                columns={ [
                  { title: 'ID', field: 'id_tag', type: 'numeric', editable:'never', hidden: true},
                  { title: 'Nome', field: 'nome_tag' },
                  { title: 'Condição', field: 'condicao'},
                  { title: 'Ativo', field: 'ic_ativo', type: 'boolean'},
                ]}
                entity_endpoint="tags"
                id_field="id_tag"

                title = "Selecione a Tag"
                options={{
                  selection: true
                }}
                not_editable = {true}
                actions={[
                  {
                    icon: "add",
                    tooltip: 'ação no usuário',
                    onClick: (evt, data) => {
                      this.setState({modal: false,parent_data: data})
                    }
                  }
                ]}
                />
            </Grid>:<React.Fragment>
            <Grid item>
              <CustomDropDown label="Grupo" entity_endpoint="group" id_field="id_grupo" label_field="nome_grupo" onChange={e => this.changeHandler(e)}/>
            </Grid>
            <Grid item>
            <CustomTable
                columns={ [
                    { title: 'ID Grupo', field: 'id_grupo', type: 'numeric', editable:'never', hidden: true},
                    { title: 'ID Tag', field: 'id_tag', type: 'numeric', editable:'never', hidden: true},
                    { title: 'Nome', field: 'nome_tag', editable:'never'},
                    { title: 'Condição', field: 'condicao' , initialEditValue: 'initial edit value'},        
                    { title: 'Ativo', field: 'ic_ativo', type: 'boolean' , editable:'never'},
                  ]}
                entity_endpoint={`group/${selected_id}/tags`}
                id_field="id_tag"
                title = "Tags / Condições"
                id_dropdown = {{"id_grupo" : selected_id}}
                parent_data = {parent_data}
                action_type = {action_type}
                not_editable = {true}
                actions={[
                  {
                    icon: 'add',
                    tooltip: 'Adicionar Tag(s)',
                    isFreeAction: true,
                    onClick: (event) => {this.setState({modal: true,action_type: "I"})}
                  },
                  {
                    icon: 'delete',
                    tooltip: 'Remover Tag(s)',
                    isFreeAction: true,
                    onClick: (event) => {this.setState({modal: true,action_type: "D"})}
                  },

                ]}
                />
            </Grid></React.Fragment>}

          </Grid>
        </Container>       
      </React.Fragment>
    )
  }
}

export default  withRouter(withStyles(useStyles, { withTheme: true })(VWgrouptags))