import React from "react"

import Typography from "@material-ui/core/Typography"
import Paper from "@material-ui/core/Paper"
import Container from "@material-ui/core/Container"
import { withStyles } from "@material-ui/core/styles"
import { withRouter } from "react-router-dom"
import Avatar from '@material-ui/core/Avatar';
import Grid from "@material-ui/core/Grid"

import imgVitor from './../../assets/images/squad/Vitor.jpg'
import imgAlexandre from './../../assets/images/squad/Alexandre.jpg'
import imgAntonildes from './../../assets/images/squad/Antonildes.jpg'
import imgTailine from './../../assets/images/squad/Tailine.jpg'
import imgVeiga from './../../assets/images/squad/Veiga.jpg'
import imgRodrigo from './../../assets/images/squad/Rodrigo.jpg'
import imgMachado from './../../assets/images/squad/Machado.jpg'


const useStyles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  large: {
    width: "auto",
    height: "auto",
    
  },

})


class VWaboutsquad extends React.Component {
  constructor(props) {
    super(props)
    this.history = props.history
  }
 

  handleIconClick = () => {
    this.history.goBack()
  }
  render() {
    const { classes } = this.props
    return (
      <React.Fragment>
        <Container component="main" maxWidth="lg">
          <Paper className={classes.paper} elevation={3}>


          <Typography component="h1" variant="h4">
          Squad Clinico-Radiológico
          </Typography>
          <br/><br/>
          <Typography component="h1" variant="h5">
          <p>Time de Inteligência de Dados voltado para desenvolvimento de ferramentas de inteligência artificial usando dados Clínicos e Radiológicos para promover impacto na prática médica e assistencial</p>
          </Typography>
          <br/><br/><br/>
          <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          >
          <Grid item xs={4} spacing={1} >
          <Avatar alt="A" src={imgAntonildes} className={classes.large} />
          <Typography component="h1" variant="h5">
          Antolides Nascimento Assunção Junior,MD, PHD
          </Typography>
          <Typography component="h1" variant="h6">
          Cientista de Dados, Médico Cardiologista,Doutor pelo Incor
          </Typography>

          </Grid>

          <Grid item xs={4} spacing={1} >
          <Avatar alt="A" src={imgAlexandre} className={classes.large} />
          <Typography component="h1" variant="h5">
          Alexandre Jorge Castanheira
          </Typography>
          <Typography component="h1" variant="h6">
          Data Engineer
          </Typography>

          </Grid>

          <Grid item xs={4} spacing={1} >
          <Avatar alt="A" src={imgMachado} className={classes.large} />
          <Typography component="h1" variant="h5">
          Felipe Augusto de Moraes Machado 
          </Typography>
          <Typography component="h1" variant="h6">
          Engenheiro Mecatrônica em formação, Cientista de dados
          </Typography>

          </Grid>

          <Grid item xs={4} spacing={1} >
          <Avatar alt="F" src={imgVeiga} className={classes.large} />
          <Typography component="h1" variant="h5">
          Felipe Veiga Rodrigues,MD, MBA
          </Typography>
          <Typography component="h1" variant="h6">
          Product Owner, Médico Radiologista e Coordenador TI e Imagens
          </Typography>

          </Grid>

          <Grid item xs={4} spacing={1} >
          <Avatar alt="R" src={imgRodrigo} className={classes.large} />
          <Typography component="h1" variant="h5">
          Rodrigo Cabrera Castaldoni
          </Typography>
          <Typography component="h1" variant="h6">
          Engenheiro Biomédico e Cientista da Computação em formação, Cientista de Dados 
          </Typography>

          </Grid>

          
          <Grid item xs={4} spacing={1}>
          <Avatar alt="T" src={imgTailine} className={classes.large} />
          <Typography component="h1" variant="h5">
          Tailine Aimi Suzuki
          </Typography>
          <Typography component="h1" variant="h6">
          Cientista de Dados
          </Typography>

          </Grid>

          <Grid item xs={4} spacing={1}>
          <Avatar alt="VB" src={imgVitor} className={classes.large} />
          <Typography component="h1" variant="h5">
          Vitor Gentil Bellot
          </Typography>
          <Typography component="h1" variant="h6">
          Full Stack Developer
          </Typography>

          </Grid>


          </Grid>
          </Paper>
        </Container>
      </React.Fragment>
    )
  }
}

export default withRouter(withStyles(useStyles, { withTheme: true })(VWaboutsquad))
