import React from "react"
import { withRouter } from "react-router-dom"
import { withStyles } from "@material-ui/core/styles"
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import CustomTable from "../../components/datagrid/comumdatagrid"
import CustomDropDown from "../../components/dataselector/comumdropdown"


const useStyles = (theme) => ({
    container: {
      marginTop: theme.spacing(5),     
    },
  })

class VWgroupapplications extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      selected_id: 0,
      modal: false,
      parent_data:[],
      action_type:null,
    }
  }
 
  changeHandler = (e) => {
    this.setState({selected_id: e})
  }

  render() {
    const { classes } = this.props
    const { selected_id, modal, parent_data,action_type } = this.state

    return (
      <React.Fragment>
        <Container maxWidth="lg" className={classes.container}>
          <Grid
            container
            spacing={3}
            direction="column"
            justify="space-between"
            alignItems="stretch">
            {modal === true?
            <Grid item>
              <CustomTable
                columns={ [
                    { title: 'ID', field: 'id_hash_api', hidden: true },
                    { title: 'Name', field: 'nome_aplicacao' },
                    { title: 'Ativo', field: 'ic_ativo', type: 'boolean'},
                ]}

                
                entity_endpoint="application"
                id_field="id_hash_api"

                title = "Selecione a Aplicação"
                options={{
                  selection: true
                }}
                not_editable = {true}
                actions={[
                  {
                    icon: "add",
                    tooltip: 'adicionar ou remover aplicação do grupo',
                    onClick: (evt, data) => {
                      this.setState({modal: false,parent_data: data})
                    }
                  }
                ]}
                />
            </Grid>:<React.Fragment>
            <Grid item>
              <CustomDropDown label="Grupo" entity_endpoint="group" id_field="id_grupo" label_field="nome_grupo" onChange={e => this.changeHandler(e)}/>
            </Grid>
            <Grid item>
            <CustomTable
                columns={ [
                    { title: 'ID hashs_api_grupos', field: 'id_hashs_api_grupos', type: 'numeric', editable:'never', hidden: true},
                    { title: 'ID grupos', field: 'id_grupos', type: 'numeric', editable:'never', hidden: true},
                    { title: 'ID hash_api', field: 'id_hash_api', type: 'numeric', editable:'never', hidden: true},

                    { title: 'Aplicação', field: 'nome_aplicacao', editable:'never'},
                  ]}
                entity_endpoint={`group/${selected_id}/application`}
                id_field="id_hash_api"
                title = "Aplicações"
                id_dropdown = {{"id_grupo" : selected_id}}
                parent_data = {parent_data}
                action_type = {action_type}
                not_editable = {true}
                actions={[
                  {
                    icon: 'add',
                    tooltip: 'Adicionar Aplicações',
                    isFreeAction: true,
                    onClick: (event) => {this.setState({modal: true,action_type: "I"})}
                  },
                  {
                    icon: 'delete',
                    tooltip: 'Remover Aplicações',
                    isFreeAction: true,
                    onClick: (event) => {this.setState({modal: true,action_type: "D"})}
                  },

                ]}
                />
            </Grid></React.Fragment>}

          </Grid>
        </Container>       
      </React.Fragment>
    )
  }
}

export default  withRouter(withStyles(useStyles, { withTheme: true })(VWgroupapplications))