import React from "react"

import Typography from "@material-ui/core/Typography"
import Paper from "@material-ui/core/Paper"
import Container from "@material-ui/core/Container"
import { withStyles } from "@material-ui/core/styles"
import { withRouter } from "react-router-dom"
import {updateMessageClick} from "../../services/urltracker/urltracker"


const useStyles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

})


class VWredirect extends React.Component {
  constructor(props) {
    super(props)
    this.history = props.history
  }
  
  componentDidMount() {    

    //console.log(this.props.location.state.external_id)

    updateMessageClick(this.props.location.state.external_id).then(data => {
       
       //console.log(data)
       //window.location.replace(this.props.location.state.url)
       window.open(this.props.location.state.url,'_blank')
       this.history.goBack()
    })

  }
  

  handleIconClick = () => {
    this.history.goBack()
  }
  render() {
    const { classes } = this.props
    return (
      <React.Fragment>
        <Container component="main" maxWidth="lg">
          <Paper className={classes.paper} elevation={3}>


          <Typography component="h1" variant="h4">
          Redirecionando para...
          </Typography>
          <br></br>
          <Typography component="h1" variant="h5">
          <p>{this.props.location.state.url}</p>

          </Typography>
          </Paper>
        </Container>
      </React.Fragment>
    )
  }
}

export default withRouter(withStyles(useStyles, { withTheme: true })(VWredirect))
