import React from "react"

import Typography from "@material-ui/core/Typography"
import Paper from "@material-ui/core/Paper"
import Container from "@material-ui/core/Container"
import { withStyles } from "@material-ui/core/styles"
import { withRouter } from "react-router-dom"
import Loader from "../../components/loader/loader"

const useStyles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

})


class VWpushintegration extends React.Component {
  constructor(props) {
    super(props)
    this.history = props.history
    this.state = {
        integrated: false,
      }
    
  }

  componentDidMount() {    
    if(localStorage.getItem("user_id") !== null){
      window.persist_onesignal_id(localStorage.getItem("user_id"))
    }



  }
  
  


  render() {
    const { classes } = this.props
    return (
      <React.Fragment>
        <Container component="main" maxWidth="lg">
          <Paper className={classes.paper} elevation={3}>
          <Typography component="h1" variant="h4">
          ... Vinculando serviço de alertas ...
          </Typography>
          <Loader/>
          </Paper>
        </Container>
      </React.Fragment>
    )
  }
}

export default withRouter(withStyles(useStyles, { withTheme: true })(VWpushintegration))
