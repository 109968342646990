import api from "../api/api"

export const updateMessageClick = async (externalMessageId) =>{

  let myendpoint = "webhook/clicked"


  console.log("BODY MESSAGE CLICK ---->" + myendpoint)
  let postbody = {id: `${externalMessageId}`}
  let body =  {id_hash_api: 1, id_tag: 2, id_usuario: 13, id_grupo: 1, nome_aplicacao: "violeta",nome_tag:"tag2"}
  console.log(postbody)
  api
  .post(myendpoint,postbody)
  .then((res) => {
    console.log("SUCESSO MESSAGE CLICK")
    console.log(res.data)
    return res})
  .catch((err) => {
    console.log("MESSAGE CLICK ERRO ---> " + myendpoint)
    console.log(err)
    return null})
}
