import api from "../api/api"

export const setUserClaim = async (login) =>{
    return api
    .get(`user/login/${login}`)
    .then((res) => {
      if(res.data !== null)
            {
        localStorage.setItem("user_id",res.data[0].id_usuario)
        console.log("REGISTROU O USER ID -->" + res.data[0].id_usuario)
            }
      return true
    })
    .catch((err) => {
      return false})
}
    