import axios from "axios"

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

instance.defaults.headers.common["Content-Type"] = "application/json"
instance.defaults.headers.common["application"] = process.env.REACT_APP_HEADER_APP
instance.defaults.headers.common["application-token"] = process.env.REACT_APP_HEADER_APP_TOKEN



instance.interceptors.response.use((response) => {
  //console.log(response.headers)
  return response;
},(error) => {
  return Promise.reject(error);
})



export default instance
