import { createMuiTheme } from "@material-ui/core/styles"
const theme = createMuiTheme({

 
  palette: {
    type: "dark",
    primary: {
      light: "#757ce8",
      main: "#AB47BC",
      dark: "#AB47BC",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#A885AB",
      dark: "#A885AB",
      contrastText: "#000",
    },
  },
  typography: {
    fontSize: 12,
  },

    MuiButton: {
      root: {
        background: 'linear-gradient(45deg, #3f50b5 30%, #21CBF3 90%)',
        fontSize: '0.8rem',
      },
      contained:
      {
        variant:"contained"
      },
      text: {
        borderRadius: 9,
        color: "white"
      },
    },

})
export default theme
